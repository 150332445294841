<template>
  <div class="transaction">
    <div class="boxContent">
      <!-- 头部导航区域 -->
      <div class="navbar main-container">
        <Navbar :itemIndex="'7'" />
      </div>
    </div>
    <!-- banner区域 -->
    <div class="barnner-bg bg-pic">
      <div class="banner main-container">
        <div class="banner-title">
          交易平台
        </div>
        <div class="see-more">
          资金交易是银行资金业务的发起点，也是华炫鼎盛解决方案的入口，华炫鼎盛在银行与分行间提供结售汇与外汇交易报价平台；在分行与对公、对私客户间提供代客交易平台；在银行与银行间外汇与同业市场里提供做市商报价平台。
        </div>
      </div>
    </div>
    <div class="tab-box">
      <ul>
        <li @click="handleTab(0)" :class="bgColor == 0 ? 'bg-color' : ''">结售汇与外汇报价交易平台</li>
        <li @click="handleTab(1)" :class="bgColor == 1 ? 'bg-color' : ''">做市商报价平台</li>
        <li @click="handleTab(2)" :class="bgColor == 2 ? 'bg-color' : ''">代客交易平台</li>
      </ul>
    </div>
    
    <!-- 结售汇与外汇报价交易平台 -->
    <div v-show="tab0" class="foreign-exchange-quotation">
      <div class="foreign-exchange-top">
        <div><img src="@/assets/transaction/jsh_img.png" alt="" /></div>
        <ul>
          <li>结售汇与外汇报价交易平台</li>
          <li>随著银行业务的拓展以及客户使用场景的变迁，银行必须建置越来越多的交易系统以满足客户的需求，包含同业间的交易系统、总分行交易系统、代客交易系统，甚至网银以及移动端都得提供相应的交易系统。但这些系统是由不同的系统供应商所开发的，导致了同样的报价功能出现在每一个交易系统中，由于各系统的报价处理逻辑不一样，使得不同系统中价格混乱的情况经常发生，甚至出现了平盘时价格倒挂的现象，给银行带来不小的损失</li>
          <li>有鉴于此，华炫鼎盛提出了报价中心的概念，打造了结售汇与外汇报价交易平台，带给银行以下的好处：
              避免重复建置：做市商、资金、同业、代客系统均不需重复开发报价功能，接收本平台报价即可；
              提高报价效能：数据总线不会被重复占用，提高系统效能同时降低数据源费用；
              避免人为制造风险：全行各业务参考统一的行内报价，避免客户交易价与平盘价倒挂导致的损失；
              提高银行竞争力：通过专业的金融模型协助银行控制价格风险，合理降低双边报价价差，从而提升银行的价格竞争力；
              满足合规要求：交易、风控、核算均采集相同的日终价，满足合规要求</li>
        </ul>
      </div>
      <div class="system-advantages-img">
          <div class="advantages">
            <div class="title">系统优势</div>
            <div class="system-advantages-box">
              <ul>
                <li>报价中心</li>
                <li>将结售汇与外汇报价作为全行范围内的报价服务 (Service)，该服务被周边业务所消费，如同业、做市、代客、平盘等；</li>
              </ul>
              <ul>
                <li>多数据源</li>
                <li>可接收多个数据源如路透、彭博、外汇交易中心，经过处理后制订出最适合本行的价格；</li>
              </ul>
            </div>
            <div class="system-advantages-box">
              <ul>
                <li>容限处理</li>
                <li>剔除数据源报价中的毛刺，避免突然的价格偏离给本行带来因价格倒挂导致的损失；</li>
              </ul>
              <ul>
                <li>提升竞争力</li>
                <li>混合多路数据源、从中挑选最优价格，加上经过金融模型校准过的价差，使银行在价格风险可控的情况下提升报价的竞争力；</li>
              </ul>
            </div>
            <div class="system-advantages-box">
              <ul>
                <li>用户画像</li>
                <li>累积不同数据源的报价数据建立对手用户画像，当银行未来与这些对手交易、平盘时能起到决策辅助效用；</li>
              </ul>
              <ul>
                <li>分业务管理</li>
                <li>通过可分离前端，方便嵌入银行现有的各种系统，在不增加交易员工作量情况下，完成报价的工作。</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="process-flow">
          <div class="process-flow-title">结售汇与外汇报价交易平台的数据处理流程</div>
          <div class="process-flow-img">
            <img src="@/assets/transaction/jsh_lct.png" alt="" />
          </div>
        </div>
    </div>
    <!-- 做市商报价平台 -->
    <div v-show="tab1" class="quotation-platform">
      <div class="quotation-platform-top">
        <div><img src="@/assets/transaction/zss_img.png" alt="" /></div>
        <ul>
          <li>做市商报价平台</li>
          <li>做市商是外汇市场的重要组成之一，为了与国际外汇市场接轨，进一步活跃中国银行间外汇市场，优化报价和交易模式，交易中心于2016年启动了外汇交易新平台的规划和建设。新平台引入了交易对手分组报价、分层带量报价等新报价模式，以及订单交易、撮合交易等新业务模式。</li>
          <li>在新的交易平台下，具有做市商资格的金融机构可以通过我们的外汇做市商系统向交易中心持续发布人民币/外汇做市报价，并应答市场会员交易询价请求及成交。报价和交易类型涵盖外汇即期、远期、掉期、货币掉期和贵金属等。</li>
        </ul>
      </div>
      <div class="bank-efficiency">
        <div class="bank-efficiency-title">做市商系统带给银行的效益</div>
        <ul>
          <li>
            <img src="@/assets/transaction/zss_xy_q3.png" alt="" />
            <div>严格的授权管理、额度管理、自动化交易策略管理以及定价引擎，确保银行在外汇做市的高频交易下不致发生操作失误导致的信用风险；</div>
          </li>
          <li>
            <img src="@/assets/transaction/zss_xy_q1.png" alt="" />
            <div>更高的报价处理能力，扩大银行外汇业务处理量，使银行在外汇交易中更加有效。</div>
          </li>
          <li>
            <img src="@/assets/transaction/zss_xy_q2.png" alt="" />
            <div>完整提供外汇与贵金属的Bilateral, CCP, ESP, IND, RFQ等多种新的询价与报价模式，覆盖外汇交易中心新平台的业务场景，充分满足银行外汇业务的询报价需求；</div>
          </li>
        </ul>
      </div>
      <div class="architecture-diagram">
        <div class="architecture-diagram-title">做市商报价平台功能架构图</div>
        <div class="architecture-diagram-img">
          <img src="@/assets/transaction/zss_lct.png" alt="" />
        </div>
      </div>
    </div>
    <!-- 代客交易平台 -->
    <div v-show="tab2" class="proxy-trading-platform">
        <div class="proxy-trading-box">
          <div class="proxy-trading-top">
            <div class="proxy-trading-left"><img src="@/assets/transaction/dkjy_img.png" alt="" /></div>
            <div class="proxy-trading-right">
              <div class="proxy-trading-right-title">代客交易平台</div>
              <div class="proxy-trading-right-content">随著人民币国际化的脚步不断加快，银行对公与对私业务在交易渠道、交易品种、交易模式与定价方法上都呈现了多样化的发展，传统的结售汇系统已经无法胜任这样的变化，因此华炫鼎盛打造了银行代客交易平台，通过渠道创新、产品创新、模式创新等领先的理念协助银行提供客户更优质的服务。</div>
            </div>
          </div>
          <div class="proxy-trading-center">
            <div class="benefit">应用华炫鼎盛代客交易平台，可以带给银行以下效益：</div>
            <ul>
              <li>
                <img src="@/assets/transaction/dkjy_ico_1.png" alt="" />
                <div>充分满足客户需求</div>
                <span>支持外汇、利率、权益、固定收益、贵金属、期货、衍生品等多种代客交易品种与交易场景；</span>
              </li>
              <li>
                <img src="@/assets/transaction/dkjy_ico_2.png" alt="" />
                <div>客户响应即时</div>
                <span>系统中机构、权限、品种、点差、交易模式、核算模型等均可通过配置达成银行在得到客户新的需求后立即响应，提升获取客户能力；</span>
              </li>
              <li>
                <img src="@/assets/transaction/dkjy_ico_3.png" alt="" />
                <div>降低重复开发成本</div>
                <span>可作为代客核心系统使用，渠道可接入柜面、网银、APP等，对内可对接国结、资金、核心、总账系统，银行不需要在各相应系统中重复建置代客功能，节省管理成本；</span>
              </li>
              <li>
                <img src="@/assets/transaction/dkjy_ico_4.png" alt="" />
                <div>业务直通模式降低风险</div>
                <span>代客业务的报价、交易、风控、结算、清算与核算均直通式处理，配合在线审批作业，实现全程直通处理；避免人工错误，降低操作风险。</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="system-advantages-bg">
          <div class="system-advantages">
            <div class="advantages-title">系统优势</div>
            <div class="system-content-box" style="margin-bottom: 36px;">
              <ul>
                <li>内嵌定价引擎</li>
                <li>系统内嵌能处理1700多种金融商品的定价引擎，在IRS、CCS、外汇期权交易中允许客户采用多种不同的报价惯例与银行进行交易；交易的存续期间，银行也可以根据定价引擎做每日的估值，如果客户行权、弃权或违约，也可以计算权利金或罚金；</li>
              </ul>
              <ul>
                <li>支持多渠道</li>
                <li>包含柜面、网银、APP与第三方等都可以连接；</li>
              </ul>
            </div>
            <div class="system-content-box2">
              <ul>
                <li>多种交易模式</li>
                <li>包含点击成交以及询价交易，还可通过参数设置进行挂单交易；</li>
              </ul>
              <ul>
                <li>风控引擎</li>
                <li>通过接口与行内其他系统对接，实时进行各种风控与合规检查；</li>
              </ul>
            </div>
            <div class="system-content-box">
              <ul style="height: 82px;">
                <li>业务一体化</li>
                <li style="height: 44px;">交易达成后自动直连后台进行结算、核算、清算，存续期间自动进行核算处理并提示清算，省去大量人工作业。</li>
              </ul>
              <ul></ul>
            </div>
            
          </div>
        </div>

        <div class="proxy-trading-img">
          <div class="proxy-img-title">代客资金系统功能架构图</div>
          <div class="proxy-trading-bg"><img src="@/assets/transaction/dk_lct.png" alt="" /></div>
        </div>
      </div>
    <!-- 底部通用 -->
    <div class="boxContentFooter">
      <FooterAll />
    </div>
  </div>
</template>
<script>
  import Navbar from '../../components/public/NavBar'
  import FooterAll from '../../components/public/footerAll'
  export default {
    components: {
      Navbar,
      FooterAll,
    },
    data(){
      return{
        tab0: true,
        tab1: false,
        tab2: false,
        bgColor: 0,
        dataArray1:[
            require('@/assets/partner/cooperate_zf_01@2x.png'),
            require('@/assets/partner/cooperate_zf_02@2x.png'),
            require('@/assets/partner/cooperate_zf_03@2x.png'),
            require('@/assets/partner/cooperate_zf_04@2x.png'),
            require('@/assets/partner/cooperate_zf_05@2x.png')
        ],
        dataArray2:[
          require('@/assets/partner/cooperate_yx_01@2x.png'),
          require('@/assets/partner/cooperate_yx_02@2x.png'),
        ],
      }
    },
    methods: {
      handleTab(v) {
        if(v==0) {
          this.tab0 = true;
          this.tab1 = false;
          this.tab2 = false;
          this.bgColor = 0;
        } else if(v==1) {
          this.tab0 = false;
          this.tab1 = true;
          this.tab2 = false;
          this.bgColor = 1;
        } else if(v==2) {
          this.tab0 = false;
          this.tab1 = false;
          this.tab2 = true;
          this.bgColor = 2;
        }
      }
    }
  }

</script>
<style lang="scss" scoped>

@import "../partner/parter.scss";
.transaction {
  background: #FBFBFB;
}
.bg-pic{
  background: url('../../assets/transaction/jypt_bj.png')
  no-repeat ;
  background-size: 1920px 320px;
  background-position: 50%;
  min-width: 1180px;
}
ul,li{ padding:0;margin:0;list-style:none}

.see-more{
  width: 796px;
  height: 75px;
  padding-top: 24px;
  font-size: 16px;
  color: #ffffff;
  line-height: 26px;
  font-weight: 400;
}
.tab-box {
  width: 1180px;
  margin: 0 auto;
  box-shadow: 0px 2px 12px 0px rgba(0,0,0,0.05);
  ul {
    display: flex;
    width: 1180px;
    margin: 0 auto;
    li {
      width: 370px;
      height: 63px;
      line-height: 60px;
      margin-right: 35px;
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 16px;
      color: rgba(0,0,0,0.85);
      text-align: center;
      cursor: pointer
    }
    li:last-child {
      margin-right: 0px;
    }
    li:hover {
      width: 370px;
      height: 60px;
      background: rgba(0,91,255,0.05);
      border-bottom: 3px solid #005BFF;
    }
  }
  .bg-color {
    width: 370px;
    height: 60px;
    background: rgba(0,91,255,0.05);
    border-bottom: 3px solid #005BFF;
  }
}
.tabs-content {
  width: 1180px;
  margin: 0 auto;
}
.foreign-exchange-quotation {
  margin-top: 40px;
  .foreign-exchange-top {
    width: 1180px;
    height: 352px;
    margin: 0 auto;
    display: flex;
    box-shadow: 0px 5px 30px 0px rgba(0,0,0,0.1);
    img {
      width: 404px;
      height: 352px;
    }
    ul {
      width: 776px;
      background: #FFFFFF;
      padding: 30px 30px 30px 40px;
      li:first-child {
        font-weight: 600;
        font-size: 26px;
        color: #1B314E;
        line-height: 30px;
      }
      li:nth-child(2) {
        font-size: 14px;
        color: #666666;
        line-height: 26px;
        margin-top: 20px;
      }
      li:last-child {
        font-size: 14px;
        color: #666666;
        line-height: 26px;
        margin-top: 20px;
      }
    }
  }
  .system-advantages-img {
    background: url('../../assets/transaction/jsh_xtys_bj.png') no-repeat ;
    background-size: 1920px 513px;
    background-position: 50%;
    min-width: 1180px;
    .advantages {
      width: 1180px;
      height: 513px;
      margin: 0 auto;
      .title {
        text-align: center;
        font-weight: 600;
        font-size: 32px;
        color: #1B314E;
        line-height: 38px;
        margin-top: 40px;
        padding-top: 50px;
        padding-bottom: 40px;
      }
      .system-advantages-box {
        display: flex;
        margin-bottom: 36px;
        ul {
          width: 570px;
          height: 82px;
          li:first-child {
            font-weight: 600;
            font-size: 20px;
            color: #0F4994;
            line-height: 23px;
            margin-bottom: 10px;
          }
          li:last-child {
            width: 570px;
            height: 44px;
            font-size: 14px;
            color: #666666;
            line-height: 22px;
          }
        }
        ul:last-child {
          margin-left: 40px;
        }
      }
    }
  }
  .process-flow {
    width: 1519px;
    height: 642px;
    margin: 0 auto;
    background: linear-gradient( 180deg, rgba(0,89,195,0) 0%, rgba(0,89,195,0.07) 100%);
    .process-flow-title {
      width: 1180px;
      padding-top: 50px;
      padding-bottom: 40px;
      font-weight: 600;
      font-size: 32px;
      color: #1B314E;
      line-height: 38px;
      text-align: center;
      margin: 0 auto;
    }
    .process-flow-img {
      width: 1180px;
      height: 447px;
      margin: 0 auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.quotation-platform-top {
  width: 1180px;
  height: 280px;
  margin: 0 auto;
  display: flex;
  box-shadow: 0px 5px 30px 0px rgba(0,0,0,0.1);
  img {
    width: 404px;
    height: 280px;
  }
  ul {
    width: 776px;
    background: #FFFFFF;
    padding: 30px 30px 30px 40px;
    li:first-child {
      font-weight: 600;
      font-size: 26px;
      color: #1B314E;
      line-height: 30px;
    }
    li:nth-child(2) {
      font-size: 14px;
      color: #666666;
      line-height: 26px;
      margin-top: 20px;
    }
    li:last-child {
      font-size: 14px;
      color: #666666;
      line-height: 26px;
      margin-top: 20px;
    }
  }
}
.quotation-platform {
    margin-top: 40px;
    .bank-efficiency {
      width: 1519px;
      height: 542px;
      margin: 0 auto;
      background: linear-gradient( 2deg, #ECEFF5 0%, rgba(236,239,245,0) 100%);
      .bank-efficiency-title {
        font-weight: 600;
        font-size: 32px;
        color: #1B314E;
        line-height: 38px;
        text-align: center;
        margin-top: 40px;
        padding-top: 50px;
        padding-bottom: 40px;
      }
      ul {
        display: flex;
        justify-content: space-between;
        width: 1180px;
        margin: 0 auto;
        li {
          width: 368px;
          height: 347px;
          background-color: #FFFFFF;
          box-shadow: 0px 3px 20px 0px rgba(0,0,0,0.09);
          font-size: 15px;
          color: #666666;
          line-height: 22px;
          
          img {
            width: 336px;
            height: 196px;
            padding: 16px;
          }
          div {
            width: 336px;
            padding-left: 16px;
          }
        }
      }
    }
    .architecture-diagram {
      width: 1519px;
      height: 601px;
      margin: 0 auto;
      background: linear-gradient( 180deg, rgba(0,89,195,0) 0%, rgba(0,89,195,0.07) 100%);
      .architecture-diagram-title {
        width: 1180px;
        padding-top: 50px;
        padding-bottom: 40px;
        font-weight: 600;
        font-size: 32px;
        color: #1B314E;
        line-height: 38px;
        text-align: center;
        margin: 0 auto;
      }
      .architecture-diagram-img {
        width: 1180px;
        height: 406px;
        margin: 0 auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
.proxy-trading-platform {
  .proxy-trading-box {
    width: 1180px;
    margin: 0 auto;
  }
  .proxy-trading-top {
    margin-top: 40px;
    width: 100%;
    height: 188px;
    display: flex;
    img {
      width: 404px;
      height: 188px;
    }
    .proxy-trading-right {
      width: 776px;
      background: #FFFFFF;
      padding: 30px 30px 30px 40px;
      .proxy-trading-right-title {
        font-weight: 600;
        font-size: 26px;
        color: #1B314E;
        line-height: 30px;
      }
      .proxy-trading-right-content {
        font-size: 14px;
        color: #666666;
        line-height: 24px;
        margin-top: 20px;
      }
    }
  }
  .proxy-trading-center{
    margin-top: 40px;
    width: 100%;
    height: 402px;
    .benefit {
      font-weight: 600;
      font-size: 24px;
      color: #000000;
      line-height: 28px;
      text-align: center;
      padding-top: 50px;
    }
    ul {
      display: flex;
      justify-content: space-between;
      margin-top: 50px;
      li {
        width: 277px;
        font-weight: 600;
        font-size: 20px;
        color: #0F4994;
        img {
          display: block;
          width: 50px;
          height: 50px;
          margin-bottom: 24px;
        }
        span {
          display: block;
          width: 277px;
          height: 96px;
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          line-height: 24px;
          margin-top: 10px;
        }
      }
    }
  }
  .system-advantages-bg {
    background: url('../../assets/transaction/dkjy_xt_bj.png') no-repeat ;
    background-size: 1920px 523px;
    background-position: 50%;
    min-width: 1180px;
    .system-advantages {
      width: 1180px;
      margin: 0 auto;
      .advantages-title {
        text-align: center;
        font-weight: 600;
        font-size: 32px;
        color: #1B314E;
        line-height: 38px;
        margin-bottom: 50px;
        padding-top: 50px;
      }
      .system-content-box {
        display: flex;
        margin-left: 87px;
        ul {
          width: 570px;
          height: 104px;
          li:first-child {
            font-weight: 600;
            font-size: 20px;
            color: #0F4994;
            line-height: 23px;
            margin-bottom: 10px;
          }
          li:last-child {
            width: 570px;
            height: 66px;
            font-size: 14px;
            color: #666666;
            line-height: 22px;
          }
        }
        ul:last-child {
          margin-left: 40px;
          li:first-child {
            width: 400px;
          }
          li:last-child {
            width: 400px;
          }
        }
      }
      .system-content-box2 {
        display: flex;
        margin-left: 87px;
        margin-bottom: 36px;
        ul {
          width: 570px;
          height: 60px;
          li:first-child {
            font-weight: 600;
            font-size: 20px;
            color: #0F4994;
            line-height: 23px;
            margin-bottom: 10px;
          }
          li:last-child {
            width: 570px;
            height: 44px;
            font-size: 14px;
            color: #666666;
            line-height: 22px;
          }
        }
        ul:last-child {
          margin-left: 40px;
          li:first-child {
            width: 400px;
          }
          li:last-child {
            width: 400px;
          }
        }
      }
    }
  }
  .proxy-trading-img {
    width: 1519px;
    height: 620px;
    margin: 0 auto;
    background: linear-gradient( 180deg, rgba(0,89,195,0) 0%, rgba(0,89,195,0.07) 100%);
    .proxy-img-title {
      padding-top: 50px;
      padding-bottom: 40px;
      font-weight: 600;
      font-size: 32px;
      color: #1B314E;
      line-height: 38px;
      text-align: center;
    }
    .proxy-trading-bg {
      width: 1180px;
      height: 406px;
      margin: 0 auto;
      img {
        width: 100%;
        height: 425px;
      }
    }
  } 
}
</style>
